import { sendGAEvent } from '@next/third-parties/google';

const analyticsEnabled = true;
const gaEnabled = true;

export const CATEGORY_NAVIGATION = 'navigation';

export const trackEvent = (
  category: string,
  action: string,
  label?: string | number,
  value?: number,
  eventCallback?: () => void,
  eventTimeout?: number,
) => {
  if (!analyticsEnabled) {
    return;
  }

  const useCategory = category;
  const useAction = action;
  let useValue, useLabel;

  if (typeof label === 'undefined' && typeof value === 'undefined') {
    // only category and action are passed
    useValue = 1;
  } else if (typeof label === 'number' && typeof value === 'undefined') {
    // only category, action, and value are passed
    useValue = label;
  } else if (typeof label === 'string' && typeof value === 'undefined') {
    // only category, action, and value are passed
    useLabel = label;
    useValue = 1;
  } else {
    useValue = value;
    useLabel = label;
  }

  console.info('trackEvent', { action: useAction, category: useCategory, label: useLabel, value: useValue });

  if (gaEnabled) {
    typeof window.gtag === 'function' &&
      window.gtag('event', useAction, {
        value: useValue,
        category: useCategory,
        ...(useLabel ? { label: useLabel } : { label }),
        ...(eventCallback ? { event_callback: eventCallback } : {}),
        ...(eventTimeout ? { eventTimeout: eventTimeout } : {}),
      });

    // sendGAEvent('event', useAction, {
    //   value: useValue,
    //   category: useCategory,
    //   ...(useLabel ? { label: useLabel } : { label }),
    // });
  }
};
