import React, { ReactElement } from 'react';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import AppLocale from '@crema/services/localization';
import { useThemeContext } from '@crema/context/ThemeContextProvider';
import { useLocaleContext } from '@crema/context/LocaleContextProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export type InterviewUpColors = {
  [key in InterviewUpColor]: string;
};

export type InterviewUpColor = 'violet' | 'blue' | 'red' | 'yellow' | 'green';

interface AppThemeProviderProps {
  children: ReactElement;
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    tiny: true;
    miniature: true;
  }

  interface SvgIconPropsColorOverrides {
    violet: true;
    blue: true;
    red: true;
    yellow: true;
    green: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    disabled: true;
    violet: true;
    blue: true;
    red: true;
    yellow: true;
    green: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    xsmall: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    violet: true;
    blue: true;
    red: true;
    yellow: true;
    green: true;
    greyed: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    disabled: Palette['primary'];
    logo: InterviewUpColors;
  }

  interface PaletteOptions {
    disabled?: PaletteOptions['primary'];
    logo?: InterviewUpColors;
  }

  interface Theme {
    borderRadius: number;
    cardRadius: number;
  }
}

const AppThemeProvider: React.FC<AppThemeProviderProps> = (props) => {
  const { theme } = useThemeContext();
  const { locale } = useLocaleContext();
  const { muiLocale } = AppLocale[locale.locale];

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(theme, muiLocale)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>{props.children}</LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppThemeProvider;
