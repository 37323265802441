import React from 'react';
import { pick } from 'lodash';

export function objectGetValue(obj: any, key: string) {
  return key.split('.').reduce((acc, part) => acc && acc[part], obj);
}

export function isReactElement(obj: any): obj is React.ReactElement {
  return React.isValidElement(obj);
  // return obj.prototype && obj.prototype.isReactComponent;
}

export const parseJson = (jsonString: string | null, defaultReturn = {}) => {
  if (jsonString === null) return defaultReturn;

  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return defaultReturn;
  }
};

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export const isSyntheticEvent = (event: any): event is React.SyntheticEvent => {
  return event.nativeEvent instanceof Event;
};

export const duplicateArrayOnlyKeys = <T, X>(obj: T[], keepKeys: string[]): X[] => {
  return obj.map((item) => {
    return pick(item, keepKeys) as X;
  });
};

export const convertQuestionsListToSimple = (list: any[]): any[] => {
  return list.map((item) => {
    return {
      id: item.id,
      list: duplicateArrayOnlyKeys(item.list, ['id', 'body']),
    };
  });
};
