import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { apiUrl } from '@crema/constants/AppConst';
import { apiToken } from '@crema/helpers';

const apiConfig = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

apiConfig.interceptors.response.use(
  (res: AxiosResponse<any, any>) => res,
  (err: any) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);

apiConfig.interceptors.request.use(
  (value: InternalAxiosRequestConfig<any>) => {
    //console.log('InternalAxiosRequestConfig value: ', value);

    const token =
      (typeof sessionStorage !== 'undefined' ? sessionStorage?.getItem('token') : undefined) ||
      (typeof localStorage !== 'undefined' ? localStorage?.getItem('token') : undefined) ||
      apiToken;

    if (token) {
      value.headers.Authorization = `Bearer ${token}`;
    }

    return value;
  },
  (err: any) => {
    console.log('reject', err);
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);

export default apiConfig;
