import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ensureUniqueKey } from '@crema/helpers';
import Cookies from 'universal-cookie';

// next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
// So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
// See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L1-L5 for
// more details on NEXT_PHASE

// console.log('process.env.NEXT_PHASE', process.env.NEXT_PHASE);
// console.log('process.env.NEXT_IS_SERVER', process.env.NEXT_IS_SERVER);
// console.log('process.env', process.env);

const appVersion = true ? process.env.NEXT_PUBLIC_RELEASE_ID : process.env.NEXT_BUILD_ID;

if (process.env.NEXT_PHASE !== 'phase-production-build') {
  if (process.env.NEXT_IS_SERVER) {
    // console.log('NEXT_IS_SERVER YES');
    // server side

    if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY_2) {
      Bugsnag.start({
        apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY_2,
        appVersion: appVersion,
        plugins: [],
        releaseStage: process.env.NODE_ENV,
        // @bugsnag/plugin-aws-lambda must only be imported on the server
        // plugins: [require('@bugsnag/plugin-aws-lambda')],
      });
    }
  } else {
    // console.log('NEXT_IS_SERVER NO');
    // client side
    if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
      const universalCookies = new Cookies(null, {
        sameSite: 'strict',
        path: '/',
      });
      const userIdAtStart = universalCookies.get('auth-id');

      //console.log('UserId: at start ', userIdAtStart);
      // If preferred two separate Bugsnag projects e.g. a javascript and a node project could be used rather than a single one
      Bugsnag.start({
        apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
        appVersion: appVersion,
        plugins: [new BugsnagPluginReact()],
        releaseStage: process.env.NODE_ENV,
        user: userIdAtStart ? { id: userIdAtStart } : undefined,
        collectUserIp: true,
        context: ensureUniqueKey(),
        onError: async (event) => {
          const universalCookies = new Cookies(null, {
            sameSite: 'strict',
            path: '/',
          });
          const userIdAtError = universalCookies.get('auth-id');

          // console.log(
          //   'userIdAtStart / userIdAtError',
          //   userIdAtStart,
          //   userIdAtError
          // );

          if ((!userIdAtStart && userIdAtError) || userIdAtStart !== userIdAtError) {
            // console.log('UserId: at error ', userIdAtError);
            event.setUser(userIdAtError);
          }
        },
      });

      // test bugsnag
      // Bugsnag.notify(new Error('Test error require react'));
    }
  }
}

export default Bugsnag;
