import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import defaultConfig from '@crema/constants/defaultConfig';
import { useThemeActionsContext, useThemeContext } from './ThemeContextProvider';
import { LanguageProps } from '@crema/models/Apps';
import { getBrowserLocaleStrict, readFromStorage, setBrowserLocale, writeToStorage } from '@crema/helpers';
import { getDataForLocale } from '../../../components/src/lib/AppLngSwitcher/data';
import { useRouter } from 'next/router';
import { isEmpty } from 'lodash';

export interface LocaleContextData {
  locale: LanguageProps;
}

export interface LocaleActionsData {
  updateLocale: (locale: LanguageProps) => void;
}

export const LocaleContext = createContext<LocaleContextData>({
  locale: defaultConfig.locale,
});
export const LocaleActionsContext = createContext<LocaleActionsData>({
  updateLocale: () => {},
});

export const useLocaleContext = () => useContext(LocaleContext);

export const useLocaleActionsContext = () => useContext(LocaleActionsContext);

interface LocaleContextProviderProps {
  children: ReactNode;
}

const LocaleContextProvider: React.FC<LocaleContextProviderProps> = ({ children }) => {
  const router = useRouter();

  const { locale: nextLocale, defaultLocale, pathname, asPath, query } = router;
  // const { pathname, asPath, query } = router;
  const defLocale = nextLocale ? nextLocale : defaultLocale || 'en'; // || defaultConfig.locale.locale;

  // console.log('LocaleContextProvider locale: ', { defLocale, nextLocale });

  // const [locale, updateLocale] = useState<LanguageProps>(
  //   getDataForLocale(readFromStorage('locale', defLocale, 'string'))
  // );
  const [locale, updateLocale] = useState<LanguageProps>(getDataForLocale(defLocale));
  const { theme } = useThemeContext();
  const { updateTheme } = useThemeActionsContext();

  useEffect(() => {
    if (getBrowserLocaleStrict() && getBrowserLocaleStrict() !== nextLocale) {
      router.push({ pathname, query }, asPath, {
        locale: getBrowserLocaleStrict(),
      });
    } else if (isEmpty(getBrowserLocaleStrict()) && nextLocale && nextLocale !== defaultLocale) {
      setBrowserLocale(nextLocale);
    }
  }, [defLocale]);

  // useEffect(() => {
  //   // console.log('should update locale in storage ? ', locale);
  //
  //   if (locale.locale !== getBrowserLocaleStrict()) {
  //     setBrowserLocale(locale.locale);
  //
  //     // updateProfileLocale(locale.locale).then((res) => {
  //     //   // console.log('updateProfileLocale', res);
  //     //   // if (res === true) {
  //     //   //
  //     //   // }
  //     // });
  //   } else {
  //     //console.log('no need to update locale in storage');
  //   }
  // }, [locale, nextLocale]);

  useEffect(() => {
    updateTheme({
      ...theme,
    });
  }, [locale, theme, updateTheme]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
      }}
    >
      <LocaleActionsContext.Provider
        value={{
          updateLocale,
        }}
      >
        {children}
      </LocaleActionsContext.Provider>
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;
