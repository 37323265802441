import { number } from 'yup';
import { ReadonlyURLSearchParams } from 'next/navigation';

export const checkPermission = (routeRole: any, userRole: any) => {
  if (!routeRole || !routeRole) {
    return true;
  }

  if (userRole && Array.isArray(userRole) && !Array.isArray(routeRole)) {
    return userRole.indexOf(routeRole) >= 0;
  }
  if (routeRole.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeRole)) {
    return routeRole.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeRole.indexOf(userRole) >= 0;
};

// export const extractIdsFromUri = (query: ParsedUrlQuery, key: string) => {
//   let values: number[] = [];
//   if (!query) {
//     return values;
//   }
//
//   if (query.skills === undefined) {
//     values = [];
//   } else if (typeof query. === 'string') {
//     if (query.skills === '') {
//       skills = [];
//     } else if (query.skills.indexOf(',')) {
//       skills = query.skills.split(',').map((skillId) => {
//         return Number(skillId);
//       });
//     } else {
//       console.log(
//         'selectedSkills --skilId-- from string',
//         query.skills,
//         Number(query.skills)
//       );
//       skills = [Number(query.skills)];
//     }
//   } else {
//     skills = query.skills.map((skilId) => {
//       console.log('selectedSkills --skilId-- from map', skilId);
//       return Number(skilId);
//     });
//
//     return skills;
//   }
// };

export const extractIdsFromUri = (searchParams: ReadonlyURLSearchParams, key: string) => {
  const values = searchParams.get(key);
  if (!values) {
    return [];
  }

  if (values.indexOf(',')) {
    return values.split(',').map((id) => {
      return Number(id);
    });
  } else if (values) {
    console.log('only one number');
    return [Number(values)];
  } else {
    return [];
  }
};

export const extractIdsFromRouterQuery = (values: string | string[] | undefined) => {
  if (!values) {
    return [];
  }

  if (typeof values === 'string' && values.indexOf(',')) {
    return values.split(',').map((id) => {
      return Number(id);
    });
  } else if (values) {
    return [Number(values)];
  } else {
    return [];
  }
};

export const extractIdFromUri = (searchParams: ReadonlyURLSearchParams, key: string, defaultValue = -1) => {
  const value = searchParams.get(key);
  if (value === null) {
    return defaultValue;
  }

  return Number(value);
};
