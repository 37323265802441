import axios from '@crema/services/axios';
import { AxiosInstance, AxiosResponse } from 'axios';
import { apiUrl } from '@crema/constants/AppConst';
import { getBrowserLocale, isSame } from '@crema/helpers';
import Cookies from 'universal-cookie';
import { addSeconds } from 'date-fns';

const universalCookies = new Cookies(null, {
  sameSite: 'strict',
  path: '/',
});

const jwtAxios: AxiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': getBrowserLocale(),
  },
});
jwtAxios.interceptors.response.use(
  (res: AxiosResponse<any, any>) => res,
  (err: any) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (token?: string, expires_in?: number) => {
  if (isSame(expires_in, '-1')) {
    expires_in = 30 * 24 * 60 * 60;
  }

  if (token) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;

    if (token !== universalCookies.get('auth-token') && expires_in) {
      universalCookies.set('auth-token', token, {
        maxAge: expires_in,
      });
    }
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');

    universalCookies.remove('auth-token');
  }
};

export const setAuthUserId = (userId?: string | number, expires_in?: number) => {
  if (isSame(expires_in, '-1')) {
    expires_in = 30 * 24 * 60 * 60;
  }

  if (userId) {
    if (userId.toString() !== String(universalCookies.get('auth-id')) && expires_in) {
      // only set the cookie if it's different from the current value
      universalCookies.set('auth-id', userId.toString(), {
        maxAge: expires_in,
      });
    }
  } else {
    universalCookies.remove('auth-id');
  }
};

export const setAuthData = (token?: string, userId?: string | number, expires_in?: number) => {
  if (isSame(expires_in, '-1')) {
    expires_in = 30 * 24 * 60 * 60;
  }

  if (!userId || !token || !expires_in) {
    universalCookies.remove('auth-id');
    universalCookies.remove('auth-token');
    universalCookies.remove('auth-expires-in');

    //
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');

    return;
  }

  jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;

  universalCookies.set('auth-id', userId.toString(), {
    maxAge: expires_in,
  });

  universalCookies.set('auth-token', token, {
    maxAge: expires_in,
  });

  const expiresAt = addSeconds(new Date(), expires_in);

  universalCookies.set('auth-expires-at', expiresAt, {
    maxAge: expires_in,
  });
};

export const logout = () => {
  // clear storage
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');

  // clear cookies
  setAuthToken();
  setAuthUserId();
  setAuthData();
};

export default jwtAxios;
