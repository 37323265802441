import React, { useEffect } from 'react';
import * as CookieConsent from 'vanilla-cookieconsent';
import pluginConfig from './CookieConsentConfig';
import Link from 'next/link';

const CookieConsentComponent = () => {
  useEffect(() => {
    CookieConsent.run(pluginConfig);
  }, []);

  return (
    /*<Link href='/cookie-preferences' className='mx-3'>
        Cookie preferences
      </Link>*/
    <a
      href='#'
      onClick={(event) => {
        event.preventDefault();
        CookieConsent.showPreferences();
        return false;
      }}
      className={'mx-3'}
    >
      Cookie preferences
    </a>
  );
};

export default CookieConsentComponent;
