import React from 'react';
import Header from '@src/modules/home/Header';
import '/public/styles/landing/index.scss';
import Footer from '@src/modules/home/Footer';
import AppInfoView from '@crema/components/AppInfoView';

// eslint-disable-next-line react/display-name
const withLayout = (ComposedComponent: any) => (props: any) => {
  const productRef = React.useRef<HTMLElement>(null);
  const aboutRef = React.useRef<HTMLElement>(null);

  return (
    <>
      <div className='main-page-wrapper'>
        <Header whiteHeader={props.whiteHeader} redirectUrl={'/'} productRef={productRef} aboutRef={aboutRef} />
        <AppInfoView />
        <ComposedComponent {...props} productRef={productRef} aboutRef={aboutRef} />
        <Footer {...props.footer} />
      </div>
    </>
  );
};

export default withLayout;
