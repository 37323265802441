import React from 'react';
import Typography from '@mui/material/Typography';
import { Fonts } from '@crema/constants/AppEnums';
import AppCardMedia from '@crema/components/AppCardMedia';
// import { TeamData } from '@crema/fakedb/extraPages';
import { alpha, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { trackEvent } from '../../../../../../helpers/src/Analytics';
import { TeamData } from './index';

type MemberItemProps = {
  member: TeamData;
};

const MemberItem = ({ member }: MemberItemProps) => {
  return (
    <AppCardMedia
      cardMedia={member.srcImg}
      cardMediaAction={
        <IconButton
          sx={{ color: 'white' }}
          aria-label='more'
          size='small'
          href={member.linkedin}
          target='_blank'
          onClick={() => {
            trackEvent(
              'homepage-team',
              'homepage-team-click-member-' + member.id,
              undefined,
              1,
              // () => {
              //   window.location.href = member.linkedin;
              //   // window.open('https://www.linkedin.com/in/' + member.name.toLowerCase().replace(' ', '-'), '_blank');
              // },
              // 100,
            );

            // navigator.sendBeacon(
            //   'https://www.google-analytics.com/collect',
            //   JSON.stringify({
            //     event: 'exit_intent',
            //     category: 'User Engagement',
            //     label: 'User is leaving the page',
            //   }),
            // );
          }}
        >
          <LinkedInIcon />
        </IconButton>
      }
      sxCardMediaAction={{
        // backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.7),
        // color: theme.palette.background.paper,
        p: 0,
        // pb: 0,
        // px: 0,
        borderRadius: 7.5,
        // position: 'absolute',
        // right: 15,
        // top: 15,
        // zIndex: 1,
        // fontSize: 12,
        // fontWeight: Fonts.BOLD,
      }}
      sxStyle={{
        boxShadow: '0px 6px 10px rgba(160, 165, 185, 0.1)',
        borderRadius: 3,
        border: (theme: any) => `solid 0px ${theme.palette.divider}`,
        height: '100%',
      }}
      sxCardMedia={{
        // borderTopRightRadius: 5,
        // borderTopLeftRadius: 5,
        borderRadius: 3,
        // filter: 'grayscale(100%)',
      }}
    >
      <Typography
        component='h5'
        sx={{
          mb: 0.1,
          fontWeight: Fonts.SEMI_BOLD,
          fontSize: { xs: 16, md: 24 },
          textAlign: 'center',
          color: '#6A45FF',
        }}
      >
        {member.name}
      </Typography>
      <Typography
        sx={(theme) => ({
          fontWeight: Fonts.MEDIUM,
          mb: 4,
          fontSize: { xs: 16, md: 24 },
          textAlign: 'center',
          color: '#6A45FF',
        })}
      >
        {member.position}
      </Typography>
      <Typography
        sx={(theme) => ({
          fontWeight: Fonts.MEDIUM,
          color: theme.palette.text.secondary,
          textAlign: 'justify',
          display: {
            xs: 'none',
            sm: 'block',
          },
        })}
      >
        {member.description}
      </Typography>
    </AppCardMedia>
  );
};

export default MemberItem;
