import { CookieConsentConfig } from 'vanilla-cookieconsent';
import { appDomain, appParentDomain, googleTagManagerId } from '@crema/constants/AppConst';
import * as CookieConsent from 'vanilla-cookieconsent';
import { trackEvent } from '../../../../helpers/src/Analytics';

export const CAT_NECESSARY = 'necessary';
export const CAT_ANALYTICS = 'analytics';
export const CAT_ADVERTISEMENT = 'advertisement';
export const CAT_FUNCTIONALITY = 'functionality';
export const CAT_SECURITY = 'security';

export const SERVICE_AD_STORAGE = 'ad_storage';
export const SERVICE_AD_USER_DATA = 'ad_user_data';
export const SERVICE_AD_PERSONALIZATION = 'ad_personalization';
export const SERVICE_ANALYTICS_STORAGE = 'analytics_storage';
export const SERVICE_FUNCTIONALITY_STORAGE = 'functionality_storage';
export const SERVICE_PERSONALIZATION_STORAGE = 'personalization_storage';
export const SERVICE_SECURITY_STORAGE = 'security_storage';

const pluginConfig: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom right',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  onFirstConsent: function (cookie) {
    console.log('onFirstAction fired', cookie, this);

    console.log(
      'CookieConsent onFirstConsent:',
      CookieConsent.acceptedService(SERVICE_ANALYTICS_STORAGE, CAT_ANALYTICS),
    );

    // if (CookieConsent.acceptedService(SE, CAT_ANALYTICS)) {
    //
    // }

    if (cookie.cookie.categories.includes(CAT_NECESSARY) && cookie.cookie.categories.length === 1) {
      trackEvent('cookie_consent', 'first_consent', 'accept_necessary', 1);
    } else if (cookie.cookie.categories.length === 4) {
      trackEvent('cookie_consent', 'first_consent', 'accept_all', 1);
    }
  },

  onConsent: function ({ cookie }) {
    console.log('onConsent fired ...', cookie);

    const consentData: { [key: string]: string } = {};
    if (cookie.categories.includes(CAT_ANALYTICS)) {
      consentData[SERVICE_ANALYTICS_STORAGE] = 'granted';
    } else {
      consentData[SERVICE_ANALYTICS_STORAGE] = 'denied';
    }

    if (cookie.categories.includes('targeting')) {
      consentData['ad_user_data'] = 'granted';
      consentData['ad_personalization'] = 'granted';
      consentData['ad_storage'] = 'granted';
    } else {
      consentData['ad_user_data'] = 'denied';
      consentData['ad_personalization'] = 'denied';
      consentData['ad_storage'] = 'denied';
    }

    'gtag' in window && typeof window.gtag === 'function' && window.gtag('consent', 'update', consentData);

    console.log('CookieConsent onConsent:', CookieConsent.acceptedService(SERVICE_ANALYTICS_STORAGE, CAT_ANALYTICS));

    //For Testing
    console.log('Cookie Consent: ', consentData);
  },

  onChange: function ({ changedCategories, cookie }) {
    console.log('onChange fired ...', { changedCategories, cookie });

    const consentData: { [key: string]: string } = {};
    if (cookie.categories.includes(CAT_ANALYTICS)) {
      consentData[SERVICE_ANALYTICS_STORAGE] = 'granted';
    } else {
      consentData[SERVICE_ANALYTICS_STORAGE] = 'denied';
    }

    if (cookie.categories.includes('targeting')) {
      consentData['ad_user_data'] = 'granted';
      consentData['ad_personalization'] = 'granted';
      consentData['ad_storage'] = 'granted';
    } else {
      consentData['ad_user_data'] = 'denied';
      consentData['ad_personalization'] = 'denied';
      consentData['ad_storage'] = 'denied';
    }

    'gtag' in window && typeof window.gtag === 'function' && window.gtag('consent', 'update', consentData);

    console.log('CookieConsent onChange:', CookieConsent.acceptedService(SERVICE_ANALYTICS_STORAGE, CAT_ANALYTICS));
    console.log('Cookie Consent: ', consentData);
  },

  categories: {
    [CAT_NECESSARY]: {
      readOnly: true,
      enabled: true,
    },
    [CAT_FUNCTIONALITY]: {
      autoClear: {
        cookies: [
          {
            name: 'locale',
          },
        ],
        // reloadPage: true,
      },
    },
    [CAT_ANALYTICS]: {
      autoClear: {
        cookies: [
          {
            name: googleTagManagerId.replace(/G-/, '_ga_'),
          },
          {
            name: '_ga',
          },
        ],
        // reloadPage: true,
      },
      services: {
        [SERVICE_ANALYTICS_STORAGE]: {
          label: 'Enables storage (such as cookies) related to analytics e.g. visit duration.',
        },
      },
    },
    targeting: {},
  },

  language: {
    default: 'en',

    translations: {
      en: {
        consentModal: {
          title: "Hello traveller, it's cookie time!",
          description:
            'Our website uses tracking cookies to understand how you interact with it. The tracking will be enabled only if you accept explicitly. <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Manage preferences</a>',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Only necessary',
          showPreferencesBtn: 'Manage preferences',
          //closeIconLabel: 'Close',
          footer: `
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/tos">Terms of service</a>
          `,
        },
        preferencesModal: {
          title: 'Cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Only necessary',
          savePreferencesBtn: 'Save preferences',
          closeIconLabel: 'Close',
          sections: [
            {
              title: 'Cookie Usage',
              description:
                'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/privacy-policy" class="cc__link">privacy policy</a>.',
            },
            {
              title: 'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
              description: 'Description',
              linkedCategory: CAT_NECESSARY,
              cookieTable: {
                headers: {
                  name: 'Name',
                  domain: 'Service',
                  description: 'Description',
                  expiration: 'Expiration',
                },
                body: [
                  {
                    name: 'auth-token',
                    domain: appDomain,
                    description: 'User authentication token. It is used to authenticate the user.',
                    expiration: 'Up to 30 days',
                  },
                  {
                    name: 'auth-id',
                    domain: appDomain,
                    description: 'User',
                    expiration: 'Up to 30 days',
                  },
                  {
                    name: 'auth-expires-at',
                    domain: appDomain,
                    description: 'It stores the user authentication expiration date. ',
                    expiration: 'Up to 30 days',
                  },
                  {
                    name: 'cf_clearance',
                    domain: appDomain,
                    description:
                      'Clearance Cookie stores the proof of challenge passed. It is used to no longer issue a challenge if present. It is required to reach an origin server.',
                    expiration: 'Expires after 12 days',
                  },
                  {
                    name: 'cc_cookie',
                    domain: appDomain,
                    description:
                      'This cookie is set by the cookie compliance solution. It stores information about the categories of cookies the site uses and whether visitors have given or withdrawn consent for the use of each category. This enables site owners to prevent cookies in each category from being set in the users browser, when consent is not given. The cookie has a normal lifespan of 6 months, so that returning visitors to the site will have their preferences remembered. It contains no information that can identify the site visitor.',
                    expiration: 'Expires after 6 months',
                  },
                ],
              },
            },
            {
              title: 'Functionality Cookies',
              description:
                'Functional cookies allow us to remember choices you make about the kind of experience you want on our site and to provide you with a more personalized experience. For example, a functional cookie is required to remember which language you prefer.',
              linkedCategory: CAT_FUNCTIONALITY,
              cookieTable: {
                headers: {
                  name: 'Name',
                  domain: 'Service',
                  description: 'Description',
                  expiration: 'Expiration',
                },
                body: [
                  {
                    name: 'auth-q',
                    domain: appDomain,
                    description: 'Used to distinguish users and sessions for the purpose of error reporting.',
                    expiration: 'Expires after 1 year',
                  },
                  {
                    name: 'locale',
                    domain: appDomain,
                    description: 'Locale',
                    expiration: 'Expires after 1 year',
                  },
                ],
              },
              //auth-q
            },
            {
              title: 'Performance and Analytics cookies',
              linkedCategory: CAT_ANALYTICS,
              cookieTable: {
                headers: {
                  name: 'Name',
                  domain: 'Service',
                  description: 'Description',
                  expiration: 'Expiration',
                },
                body: [
                  {
                    name: '_ga',
                    domain: appParentDomain,
                    description: 'Used to distinguish users.',
                    expiration: 'Expires after 2 years',
                  },
                  {
                    name: googleTagManagerId.replace(/G-/, '_ga_'),
                    domain: appParentDomain,
                    description: 'Used to persist session state.',
                    expiration: 'Expires after 2 years',
                  },
                ],
              },
            },
            {
              title: 'Targeting Cookies',
              description:
                'We use Targeting cookies to deliver advertisements relevant to you and your interests when you visit other websites that host advertisements.',
              linkedCategory: 'targeting',
            },
            {
              title: 'More information',
              description:
                'For any queries in relation to our policy on cookies and your choices, please <a class="cc__link" href="/contact-us">contact us</a>.',
            },
          ],
        },
      },
    },
  },
};

export default pluginConfig;
