import { parseJson } from './ObjectHelper';

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export const readFromStorage = (key: string, defaultValue: any, expectType: 'json' | 'string' = 'json') => {
  if (typeof localStorage === 'undefined') {
    return defaultValue;
  }

  const jsonString = localStorage.getItem(key);

  if (jsonString === null) {
    return defaultValue;
  }

  if (expectType === 'json') {
    const parsedJson = parseJson(jsonString, defaultValue);

    return parsedJson;
  } else if (expectType === 'string') {
    // return jsonString ?? defaultValue;
    if (typeof jsonString === 'string') {
      return jsonString;
    } else {
      return defaultValue;
    }
  } else {
    throw new Error('Invalid expectType');
  }
};

export const writeToStorage = (key: string, value: any) => {
  if (typeof localStorage === 'undefined') {
    throw new Error('localStorage is not available');
  }

  localStorage.setItem(key, value);
};
