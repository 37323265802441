import React from 'react';
import PropTypes from 'prop-types';
import { NextSeo } from 'next-seo';
import * as process from 'process';
import { stripEndingSlack } from '@crema/helpers';

const SITE_URL =
  process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_FRONTEND_URL : process.env.NEXT_PUBLIC_FRONTEND_URL;

const ASSETS_URL =
  process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_ASSETS_URL : process.env.NEXT_PUBLIC_ASSETS_URL;

const CRAWLING_DENY = process.env.NEXT_PUBLIC_CRAWLING_DENY === 'true';

const defaultTitle = 'InterviewsUp - Interview Preparation Platform';
const defaultDescription =
  'Promoting fair candidate assessments by guiding HR and hiring managers on tailored interview questions to reduce biases';
const defaultImage = stripEndingSlack(SITE_URL) + '/favicon/android-chrome-512x512.png';
// const defaultTwitter = '@twitter_interviewsUp';
const defaultSep = ' | ';
type Props = {
  children?: React.ReactNode;

  [key: string]: any;
};
const AppPageMeta = ({ children, ...rest }: Props) => {
  const { title, description, image, keywords, canonical, category = 'InterviewsUp', tags = ['', ''] } = rest;
  const theTitle = title ? title : defaultTitle;
  // const theTitle = title
  //   ? title?.length > 48
  //     ? title
  //     : title + defaultSep + defaultTitle
  //   : defaultTitle;
  const theDescription = description ? description.substring(0, 155) : defaultDescription;
  const theImage = image ? (image.startsWith('http') ? image : `//${ASSETS_URL}${image}`) : defaultImage;

  return (
    <>
      <NextSeo
        title={theTitle}
        description={theDescription}
        noindex={!!CRAWLING_DENY}
        nofollow={!!CRAWLING_DENY}
        additionalMetaTags={
          keywords
            ? [
                {
                  name: 'keywords',
                  content: keywords,
                },
              ]
            : []
        }
        {...(canonical ? { canonical: canonical } : {})}
        openGraph={{
          ...(canonical ? { url: canonical } : {}),
          type: 'website',
          title: theTitle,
          description: theDescription,
          images: [
            {
              url: theImage,
              // width: 800,
              // height: 600,
              alt: theTitle,
              // type: 'image/jpeg',
            },
          ],
          site_name: 'InterviewsUp',
        }}
        /*twitter={{
              handle: defaultTwitter,
              site: '@twitter_interviewsUp',
              cardType: 'summary_large_image',
            }}*/
      />
      {children}
    </>
  );
};

export default AppPageMeta;

AppPageMeta.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};
