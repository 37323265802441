import { useEffect, useState } from 'react';
import MainMenu from '../header/MainMenu';
import Link from 'next/link';
import AppLogo from '@crema/components/AppLogo';

type HeaderProps = {
  whiteHeader?: boolean;
  redirectUrl?: string;
  productRef: React.RefObject<HTMLElement>;
  aboutRef: React.RefObject<HTMLElement>;
};

const Header = ({ whiteHeader = false, redirectUrl = '/', productRef, aboutRef }: HeaderProps) => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  return (
    <header
      className={`theme-main-menu sticky-menu theme-menu-three ${whiteHeader ? 'white-vr' : ''} ${
        navbar ? 'fixed' : ''
      }`}
    >
      <div className='inner-content position-relative'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='logo order-lg-0'>
            <Link href='/' className='d-block'>
              <AppLogo color={whiteHeader ? '#FFF' : undefined} textHide={false} redirectUrl={redirectUrl} />
            </Link>
          </div>
          <div className='right-widget ms-auto d-flex align-items-center order-lg-3'>
            <Link href='/signin' className='login-btn-two fw-500 d-flex align-items-center me-3'>
              Login
            </Link>
            <Link href='/signup' className='signup-btn-one tran3s position-relative ps-3 d-none d-lg-block'>
              <span className='fw-500'>Sign up</span>- It’s Free
            </Link>
          </div>
          <MainMenu productRef={productRef} aboutRef={aboutRef} />
        </div>
      </div>
    </header>
  );
};

export default Header;
