import React, { ReactNode } from 'react';
import AppLoader from './AppLoader';
import PropTypes from 'prop-types';
import { useAuthUser } from '@crema/hooks/AuthHooks';

type AuthRoutesProps = {
  children: ReactNode;
};

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  const { isLoading, authIsRequired } = useAuthUser();
  return authIsRequired && isLoading ? <AppLoader /> : <>{children}</>;
  return <>{children}</>;
};

export default AuthRoutes;

// AuthRoutes.propTypes = {
//   children: PropTypes.node.isRequired,
// };
