import { Fonts, FooterType, HeaderType, NavStyle, ThemeMode, ThemeStyleRadius } from './AppEnums';

export const textLight = {
  primary: 'rgb(17, 24, 39)',
  secondary: 'rgb(107, 114, 128)',
  disabled: 'rgb(149, 156, 169)',
};

export const textDark = {
  primary: 'rgb(255,255,255)',
  secondary: 'rgb(229, 231, 235)',
  disabled: 'rgb(156, 163, 175)',
};

export const backgroundDark = {
  paper: '#2B3137',
  default: '#1F2527',
};

export const backgroundLight = {
  paper: '#FFFFFF',
  default: '#F4F7FE',
};

const cardRadius = ThemeStyleRadius.STANDARD;
export const defaultTheme: any = {
  theme: {
    spacing: 4,
    cardRadius: cardRadius,
    borderRadius: '16px',
    direction: 'ltr',
    palette: {
      mode: ThemeMode.LIGHT,
      background: {
        paper: '#FFFFFF',
        default: '#F4F7FE',
      },
      primary: {
        main: '#2337a5',
        contrastText: '#fff',
      },
      secondary: {
        main: '#F04F47',
      },
      success: {
        main: '#11C15B',
        light: '#D9F5E5',
      },
      warning: {
        main: '#ed6c02',
        light: '#ff9800',
      },
      disabled: {
        main: '#cecece',
        light: '#e8e8e8',
      },
      yellow: {
        main: '#FFD731',
        contrastText: '#000',
      },
      blue: {
        main: '#228cff',
        contrastText: '#FFF',
      },
      violet: {
        main: '#9a4dff',
        contrastText: '#FFF',
      },
      red: {
        main: '#f16264',
        contrastText: '#FFF',
      },
      green: {
        main: '#05c1a1',
        contrastText: '#FFF',
      },
      text: textLight,
      gray: {
        50: '#fafafa',
        100: '#F5F6FA',
        200: '#edf2f7',
        300: '#E0E0E0',
        400: '#c5c6cb',
        500: '#A8A8A8',
        600: '#666666',
        700: '#4a5568',
        800: '#201e21',
        900: '#1a202c',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
      },
      greyed: {
        main: '#E0E0E0',
        contrastText: '#201e21',
      },
      logo: {
        violet: '#9a4dff',
        blue: '#228cff',
        red: '#f16264',
        yellow: '#ffd731',
        green: '#05c1a1',
      },
    },
    status: {
      danger: 'orange',
    },
    divider: 'rgba(224, 224, 224, 1)',
    typography: {
      fontFamily: ['Plus Jakarta Sans', 'Be Vietnam', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 400,
      h1: {
        fontSize: 22,
        fontWeight: 600,
      },
      h2: {
        fontSize: 20,
        fontWeight: 500,
      },
      h3: {
        fontSize: 18,
        fontWeight: 500,
      },
      h4: {
        fontSize: 16,
        fontWeight: 500,
      },
      h5: {
        fontSize: 14,
        fontWeight: 500,
      },
      h6: {
        fontSize: 12,
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: 14,
      },
      subtitle2: {
        fontSize: 16,
      },
      body1: {
        fontSize: 15,
      },
      body2: {
        fontSize: 13,
      },
    },
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
          },
        },
      },
      MuiCardLg: {
        styleOverrides: {
          root: {
            // apply theme's border-radius instead of component's default
            borderRadius:
              cardRadius === ThemeStyleRadius.STANDARD ? ThemeStyleRadius.STANDARD : ThemeStyleRadius.MODERN + 20,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            boxShadow: '0px 10px 10px 4px rgba(0, 0, 0, 0.04)',
            '& .MuiCardContent-root:last-of-type': {
              paddingBottom: 16,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
            // boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
            textTransform: 'capitalize',
          },
        },
        variants: [
          // {
          //   props: { size: 'xsmall' },
          //   style: {
          //     padding: '0px 6px',
          //   },
          // },
        ],
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 9,
          },
        },
        variants: [
          {
            props: { size: 'xsmall' },
            style: {
              padding: '5px',
            },
          },
        ],
      },
      MuiSvgIcon: {
        variants: [
          {
            props: { fontSize: 'tiny' },
            style: {
              fontSize: '1.1rem',
            },
          },
          {
            props: { fontSize: 'miniature' },
            style: {
              fontSize: '0.9rem',
            },
          },
        ],
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: Fonts.REGULAR,
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          root: {
            marginTop: '-12px',
          },
          line: {
            borderTopWidth: 3,
          },
        },
      },
      // https://github.com/mui/material-ui/issues/14427
      MuiTextField: {
        styleOverrides: {
          root: {
            // Puts the label into notched position
            '.MuiInputLabel-outlined:has(+ .MuiOutlinedInput-root > .MuiOutlinedInput-input:-webkit-autofill)': {
              transform: 'translate(14px, -9px) scale(0.75)',
            },
            // Creates a hole around the text inside the border
            '.MuiOutlinedInput-input:-webkit-autofill + .MuiOutlinedInput-notchedOutline > legend': {
              maxWidth: '100%',
            },
          },
        },
      },
    },
  },
};

export interface SidebarData {
  sidebarBgColor: string;
  sidebarTextColor: string;
  sidebarHeaderColor: string;
  sidebarMenuSelectedBgColor: string;
  sidebarMenuSelectedTextColor: string;
  mode: string;
}

export const ColorSidebar: SidebarData = {
  sidebarBgColor: '#2337a5',
  sidebarTextColor: '#fff',
  sidebarHeaderColor: '#2337a5',
  sidebarMenuSelectedBgColor: '#fffefe',
  sidebarMenuSelectedTextColor: 'rgba(0, 0, 0, 0.87)',
  mode: ThemeMode.LIGHT,
};

const defaultConfig = {
  sidebar: {
    borderColor: '#757575',
    menuStyle: 'default',
    colorSet: ColorSidebar,
  },
  themeStyle: 'standard',
  themeMode: ThemeMode.LIGHT,
  navStyle: NavStyle.HEADER_USER_MINI,
  // navStyle: NavStyle.MINI_SIDEBAR_TOGGLE,
  layoutType: 'full-width',
  footerType: FooterType.FIXED,
  headerType: HeaderType.FIXED,
  footer: true,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
};
export default defaultConfig;
