import { authRole } from '@crema/constants/AppConst';
import { AuthUserType } from '@crema/models/AuthUser';
import { BlogPageAuthorType } from '@crema/models/Blog';
import { v4 as uuidv4 } from 'uuid';
import { readFromStorage, writeToStorage } from './StorageHelper';
import defaultConfig from '@crema/constants/defaultConfig';
import Cookies from 'universal-cookie';

type UserMappingType = {
  user: string[];
  admin: string[];
  guest: string[];
};

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export const getUserFromJwtAuth = (user: any) => {
  const mapping: UserMappingType = {
    user: authRole.User,
    admin: authRole.Admin,
    guest: [],
  };

  if (user) {
    let userRole: keyof UserMappingType = 'guest';
    if (user.role && user.role in mapping) {
      userRole = user.role;
    } else {
      console.error('Invalid user role', user.role);
    }

    return {
      id: user.id,
      uid: user._id,
      name: user.name,
      email: user.email,
      avatar: user.avatar,
      role: mapping[userRole],
      email_verified: !!user.email_verified_at,
      ...(user.hash_validation ? { hash_validation: user.hash_validation } : {}),
      count_guides: user.count_guides,
      count_job_roles: user.count_job_roles,
    };
  }
  return user;
};

export const isUserAuthor = (user: AuthUserType | undefined, author: BlogPageAuthorType | undefined) => {
  if (!user || !author) {
    return false;
  }

  return user && author && user.role?.indexOf('admin') !== -1 && author.author_id === user.id;
};

const universalCookies = new Cookies(null, {
  sameSite: 'strict',
  path: '/',
});
export const apiToken = universalCookies.get('auth-token');

export const ensureUniqueKey = () => {
  let qToken = universalCookies.get('auth-q');
  if (!qToken) {
    qToken = uuidv4();

    universalCookies.set('auth-q', qToken, {
      maxAge: 365 * 24 * 60 * 60,
    });
  }

  return qToken;
};

export const getBrowserLocale = () => {
  return readFromStorage('locale', defaultConfig.locale.locale, 'string');
};

export const getBrowserLocaleStrict = () => {
  return readFromStorage('locale', '', 'string');
};

export const setBrowserLocale = (locale: string) => {
  writeToStorage('locale', locale);
};
