import { BagType, HeaderMessageType, InfoViewData, PopupMessageType } from './InfoViewContextProvider';

export const InFoViewActions = {
  FETCH_STARTS: 'FETCH_STARTS',
  FETCH_STOP: 'FETCH_STOP',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_WARNING: 'FETCH_WARNING',
  SET_MESSAGE: 'SET_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  CONSUME_MESSAGE: 'CONSUME_MESSAGE',
  SET_ERROR: 'SET_ERROR',
  CLEAR_INFOVIEW: 'CLEAR_INFOVIEW',
  HEADER_MESSAGE: 'HEADER_MESSAGE',
  CLEAR_HEADER_MESSAGE: 'CLEAR_HEADER_MESSAGE',
  CONSUME_CLEAR_HEADER_MESSAGE: 'CONSUME_CLEAR_HEADER_MESSAGE',
  POPUP_MESSAGE: 'POPUP_MESSAGE',
  CLEAR_POPUP_MESSAGE: 'CLEAR_POPUP_MESSAGE',
  CONSUME_CLEAR_POPUP_MESSAGE: 'CONSUME_CLEAR_POPUP_MESSAGE',
  IGNORE_POPUP_MESSAGE: 'IGNORE_POPUP_MESSAGE',
  SET_COUNTER: 'SET_COUNTER',
  INCREMENT_COUNTER: 'INCREMENT_COUNTER',
  SET_BAG_COUNTER: 'SET_BAG_COUNTER',
  INCREMENT_BAG_COUNTER: 'INCREMENT_BAG_COUNTER',
  SET_BAG_COUNTER_ACK: 'SET_BAG_COUNTER_ACK',
  INCREMENT_BAG_COUNTER_ACK: 'INCREMENT_BAG_COUNTER_ACK',
} as const;

export function isHeaderMessageType(
  // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
  message: any,
): message is HeaderMessageType {
  return message && message.group;
}

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export function isPopupMessageType(message: any): message is PopupMessageType {
  return message && message.group;
}

function headerMessageInGroup(message: HeaderMessageType | PopupMessageType, group: string | string[]): boolean {
  if (!message.group) {
    return false;
  }

  if (typeof group === 'string') {
    return message.group === group;
  } else {
    return group.includes(message.group);
  }
}

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export function contextReducer(state: InfoViewData, action: any) {
  switch (action.type) {
    case InFoViewActions.FETCH_STARTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case InFoViewActions.FETCH_STOP: {
      return {
        ...state,
        loading: false,
      };
    }
    case InFoViewActions.FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case InFoViewActions.FETCH_WARNING: {
      return {
        ...state,
        loading: false,
        message: '',
        error: '',
        warning: action.payload,
      };
    }
    case InFoViewActions.SET_MESSAGE: {
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: '',
        warning: '',
      };
    }
    case InFoViewActions.CLEAR_MESSAGE: {
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: '',
        warning: '',
      };
    }
    case InFoViewActions.CONSUME_MESSAGE: {
      return {
        ...state,
        message: '',
        error: '',
        warning: '',
      };
    }
    case InFoViewActions.SET_ERROR: {
      return {
        ...state,
        loading: false,
        message: '',
        error: action.payload,
        warning: '',
      };
    }
    case InFoViewActions.CLEAR_INFOVIEW: {
      return {
        ...state,
        loading: false,
        message: '',
        error: '',
        warning: '',
      };
    }

    case InFoViewActions.HEADER_MESSAGE: {
      return {
        ...state,
        headerMessage: action.payload,
      };
    }

    case InFoViewActions.CLEAR_HEADER_MESSAGE: {
      if (action && action.groups && action.groups.length > 0) {
        // clear multiple groups
        return {
          ...state,
          headerMessage:
            isHeaderMessageType(state.headerMessage) && headerMessageInGroup(state.headerMessage, action.groups)
              ? false
              : state.headerMessage,
          clearedHeaderMessage: { groups: action.groups },
        };
      } else {
        return {
          ...state,
          clearedHeaderMessage: true,
        };
      }
    }

    case InFoViewActions.CONSUME_CLEAR_HEADER_MESSAGE: {
      return {
        ...state,
        clearedHeaderMessage: false,
      };
    }

    case InFoViewActions.POPUP_MESSAGE: {
      return {
        ...state,
        popupMessage: action.payload,
      };
    }

    case InFoViewActions.CLEAR_POPUP_MESSAGE: {
      if (action && action.groups && action.groups.length > 0) {
        // clear multiple groups
        return {
          ...state,
          popupMessage:
            isPopupMessageType(state.popupMessage) && headerMessageInGroup(state.popupMessage, action.groups)
              ? false
              : state.popupMessage,
          clearedPopupMessage: { groups: action.groups },
        };
      } else {
        return {
          ...state,
          clearedPopupMessage: true,
          // ignoredPopupMessages: [],
          popupMessage: false,
        };
      }
    }

    case InFoViewActions.CONSUME_CLEAR_POPUP_MESSAGE: {
      return {
        ...state,
        clearedPopupMessage: false,
      };
    }

    case InFoViewActions.IGNORE_POPUP_MESSAGE: {
      //console.log('Ignore called state.ignoredPopupMessages', state.ignoredPopupMessages, action.payload);

      return {
        ...state,
        ignoredPopupMessages: [...state.ignoredPopupMessages, action.payload],
      };
    }

    case InFoViewActions.SET_COUNTER: {
      //console.log('called state.SET_COUNTER', action.payload);

      return {
        ...state,
        counters: {
          ...state.counters,
          ...{ [action.payload.key]: action.payload.value },
        },
      };
    }

    case InFoViewActions.INCREMENT_COUNTER: {
      // const newValue = (state.counters[action.payload.key] || 0) + action.payload.value;
      return {
        ...state,
        counters: {
          ...state.counters,
          ...{
            [action.payload.key]: (state.counters[action.payload.key] || 0) + action.payload.value,
          },
        },
      };
    }

    case InFoViewActions.SET_BAG_COUNTER: {
      //console.log('called state.SET_BAG_COUNTER', action.payload);
      const bagType = action.payload.type as BagType;

      const sbcResult = {
        ...state,
        bagCounters: {
          ...state.bagCounters,
          [bagType]: {
            ...state.bagCounters[bagType],
            ...{ [action.payload.key]: action.payload.value },
          },
        },
      };

      // console.log('called state.SET_BAG_COUNTER new state:', x);

      return sbcResult;
    }

    case InFoViewActions.INCREMENT_BAG_COUNTER: {
      // const newValue = (state.counters[action.payload.key] || 0) + action.payload.value;
      const bagType = action.payload.type as BagType;
      const ibcResult = {
        ...state,
        bagCounters: {
          ...state.bagCounters,
          [bagType]: {
            ...state.bagCounters[bagType],
            ...{
              [action.payload.key]: (state.bagCounters[bagType][action.payload.key] || 0) + action.payload.value,
            },
          },
        },
      };

      // console.log('called state.INCREMENT_BAG_COUNTER new state:', y);
      return ibcResult;
    }
    case InFoViewActions.SET_BAG_COUNTER_ACK: {
      //console.log('called state.SET_BAG_COUNTER', action.payload);
      const bagType = action.payload.type as BagType;

      const sbcResult = {
        ...state,
        bagCountersAck: {
          ...state.bagCountersAck,
          [bagType]: {
            ...state.bagCountersAck[bagType],
            ...{ [action.payload.key]: action.payload.value },
          },
        },
      };

      //console.log('called state.SET_BAG_COUNTER_ACK new state:', sbcResult);

      return sbcResult;
    }

    case InFoViewActions.INCREMENT_BAG_COUNTER_ACK: {
      // const newValue = (state.counters[action.payload.key] || 0) + action.payload.value;
      const bagType = action.payload.type as BagType;
      const ibcResult = {
        ...state,
        bagCountersAck: {
          ...state.bagCountersAck,
          [bagType]: {
            ...state.bagCountersAck[bagType],
            ...{
              [action.payload.key]: (state.bagCountersAck[bagType][action.payload.key] || 0) + action.payload.value,
            },
          },
        },
      };

      // console.log(
      //   'called state.INCREMENT_BAG_COUNTER_ACK new state:',
      //   ibcResult
      // );
      return ibcResult;
    }

    default:
      return state;
  }
}
